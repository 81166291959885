import React from 'react';
import { GetUserIdentifiersQueryResult } from '../../Admin/shared/graphql/queries/getUserIdentifiers.generated';

export type AdminUser = NonNullable<GetUserIdentifiersQueryResult['data']>['user'];

export type AuthContextType = {
  user?: AdminUser;
  loading?: boolean;
  onSignInSuccessful: (user: AdminUser) => void;
  onLogout: () => void;
};

export const AuthContext = React.createContext<AuthContextType>({ onSignInSuccessful: () => {}, onLogout: () => {} });
