import React, { useContext } from 'react';
import {
  Box,
  Avatar,
  Flex,
  Icon,
  ChakraTooltip as Tooltip,
  HamburgerIcon,
  CloseIcon,
} from '@springcare/sh-component-library';
import { connect } from 'react-redux';

import styles from './styles.module.scss';
import { SpringLogoIcon, SpringLogo } from 'components/atoms/Assets/svgs';
import Link from 'components/atoms/Link';
import EnvDetails from 'components/molecules/EnvDetails';
import { AuthContext } from 'modules/shared/context/AuthContext';

interface HeaderProps {
  isLoggedInAndInitialized?: boolean;
  mobileSideMenuIsOpen?: boolean;
  toggleMobileSideMenu?: () => any;
}

const Header = ({ mobileSideMenuIsOpen, toggleMobileSideMenu, isLoggedInAndInitialized = true }: HeaderProps) => {
  const { user } = useContext(AuthContext);
  const name = `${user?.first_name} ${user?.last_name}`;
  return (
    <header className={styles.header}>
      <button
        className="mobile_nav_button"
        aria-label={mobileSideMenuIsOpen ? 'close navigation' : 'open navigation'}
        data-testid="mobile-ham-menu"
        onClick={() => toggleMobileSideMenu?.()}
      >
        <Icon as={mobileSideMenuIsOpen ? CloseIcon : HamburgerIcon} />
      </button>
      <Flex width="100%" alignItems="center">
        <Box flex={1}>
          <Link alias={isLoggedInAndInitialized ? 'Home' : 'SignIn'} dataTestId="header-logo-link">
            <SpringLogo className={styles.logo} />
            <SpringLogoIcon className={styles.logoWithoutText} />
          </Link>
        </Box>
        <Box flex={1}>
          <EnvDetails />
        </Box>
        <Flex gap={4} flex={1} alignItems="center" justifyContent="flex-end">
          {isLoggedInAndInitialized && (
            <Tooltip label={`${name}<${user?.email}>`}>
              <Avatar size="lg" name={name} />
            </Tooltip>
          )}
          <Box>
            {isLoggedInAndInitialized ? (
              <Link key="logout" alias="Logout">
                Logout
              </Link>
            ) : (
              <Link key="sign in" alias="SignIn">
                Sign In
              </Link>
            )}
          </Box>
        </Flex>
      </Flex>
    </header>
  );
};

export default Header;
