import { Header } from '../../../components/Navigation/Header';
import { Box } from '@springcare/sh-component-library';

export const PublicLayout = ({ children }) => {
  return (
    <Box mt="85px">
      <Header isLoggedInAndInitialized={false} />
      <Box p={4}>{children}</Box>
    </Box>
  );
};
